import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  Input,
  Button,
  useColorModeValue,
  Text,
  Stack,
  Tag,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import { usePayment } from 'contexts/paymentContext';
import { MdFilterList, MdRefresh } from 'react-icons/md';

export default function PaymentTable() {
  const [payments, setPayments] = useState([]);
  const [type, setType] = useState('all');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { fetchPaymentsByType, fetchPaymentsByDateRange } = usePayment();

  // Add useEffect to load initial data
  useEffect(() => {
    const loadInitialData = async () => {
      console.log('Loading initial payment data...');
      const result = await fetchPaymentsByType('all');
      console.log('Initial payment data:', result);
      setPayments(result || []);  // Ensure we always set an array
    };
    loadInitialData();
  }, []);

  // Chakra color mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const boxBg = useColorModeValue('white', 'navy.700');
  const shadow = useColorModeValue('sm', 'none');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const hoverBg = useColorModeValue('gray.50', 'gray.800');

  const handleTypeChange = async (newType) => {
    console.log('Changing payment type to:', newType);
    setType(newType);
    const result = await fetchPaymentsByType(newType);
    console.log('Payment data for type:', newType, result);
    setPayments(result || []);
  };

  const handleDateFilter = async () => {
    if (startDate && endDate) {
      // Format dates to ISO string for consistency
      const formattedStartDate = new Date(startDate).toISOString();
      const formattedEndDate = new Date(endDate).toISOString();
      const result = await fetchPaymentsByDateRange(formattedStartDate, formattedEndDate);
      console.log('Payment data for date range:', result);
      setPayments(result || []);  // Ensure we always set an array
    }
  };

  const resetFilters = () => {
    setType('all');
    setStartDate('');
    setEndDate('');
    setPayments([]);
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'green';
      case 'pending':
        return 'yellow';
      case 'failed':
        return 'red';
      default:
        return 'gray';
    }
  };

  // Function to refresh payment data
  const refreshPayments = async () => {
    const result = await fetchPaymentsByType('all');
    setPayments(result || []);
  };

  return (
    <Card>
      <Flex direction="column" width="100%">
       

        <Flex justify="space-between" align="center" p={4} borderBottom="1px" borderColor={borderColor}>
          <Text color={textColor} fontSize="xl" fontWeight="700">
            Payment History
          </Text>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<MdFilterList />}
              variant="ghost"
              aria-label="Filter"
              size="md"
            />
            <MenuList>
              <MenuItem onClick={() => handleTypeChange('all')}>All Types</MenuItem>
              <MenuItem onClick={() => handleTypeChange('search')}>Search</MenuItem>
              <MenuItem onClick={() => handleTypeChange('collaboration')}>Collaboration</MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        <Box p={4} bg={boxBg} borderRadius="lg">
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4} mb={6}>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
              size="md"
              borderRadius="lg"
              _focus={{ borderColor: brandColor }}
            />
            <Input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
              size="md"
              borderRadius="lg"
              _focus={{ borderColor: brandColor }}
            />
            <Button
              onClick={handleDateFilter}
              colorScheme="brand"
              size="md"
              borderRadius="lg"
              px={8}
              _hover={{ transform: 'translateY(-1px)', shadow: 'lg' }}
            >
              Apply Filter
            </Button>
            <IconButton
              aria-label="Reset filters"
              icon={<MdRefresh />}
              onClick={resetFilters}
              variant="outline"
              size="md"
              borderRadius="lg"
            />
          </Stack>

          {type !== 'all' && (
            <Flex mb={4}>
              <Tag
                size="md"
                variant="subtle"
                colorScheme="brand"
                borderRadius="full"
              >
                Type: {type}
                <Button
                  size="xs"
                  ml={2}
                  onClick={() => handleTypeChange('all')}
                  variant="ghost"
                >
                  ×
                </Button>
              </Tag>
            </Flex>
          )}

          <Box overflowX="auto">
            <Table variant="simple">
              <Thead>
                <Tr>
                <Th borderColor={borderColor}>Payer</Th>
                  <Th borderColor={borderColor}>Date</Th>
                  <Th borderColor={borderColor}>Type</Th>
                  <Th borderColor={borderColor}>Amount</Th>
                  <Th borderColor={borderColor}>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {payments.map((payment, index) => (
                  <Tr key={index} _hover={{ bg: hoverBg }}>
                     <Td borderColor={borderColor}>
                      <Tag
                        size="sm"
                        variant="subtle"
                      >
                        {payment?.payer}
                      </Tag>
                    </Td>
                    <Td borderColor={borderColor}>
                      {new Date(payment.date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      })}
                    </Td>
                    <Td borderColor={borderColor}>
                      <Tag
                        size="sm"
                        variant="subtle"
                        colorScheme={payment.type === 'search' ? 'blue' : 'purple'}
                      >
                        {payment.type}
                      </Tag>
                    </Td>
                    <Td borderColor={borderColor} fontWeight="600">
                      ${(payment.amount).toFixed(2)}
                    </Td>
                    <Td borderColor={borderColor}>
                      <Badge
                        colorScheme={getStatusColor(payment.status)}
                        borderRadius="full"
                        px={3}
                        py={1}
                      >
                        {payment.status}
                      </Badge>
                    </Td>
                  </Tr>
                ))}
                {payments.length === 0 && (
                  <Tr>
                    <Td colSpan={4} textAlign="center" py={8}>
                      <Text color="gray.500">
                        No payments found. Apply filters to see payment history.
                      </Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Flex>
    </Card>
  );
}
