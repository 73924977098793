import {
  Box,
  Button,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import axios from 'axios';
import { baseURL } from 'constants/constant';

export default function Categories() {
  const [categories, setCategories] = React.useState([]);
  const [showSubCategories, setShowSubCategories] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState(null);
  const [showFAQs, setShowFAQs] = React.useState(false);
  const [editItem, setEditItem] = React.useState({
    en: '',
    es: '',
    fr: '',
    it: '',
    pg: '',
    ch: '',
    ru: '',
    categoryId: '',
  });

  const [editSubCategory, setEditSubCategory] = React.useState({
    en: '',
    es: '',
    fr: '',
    it: '',
    pg: '',
    ch: '',
    ru: '',
    subcategoryId: '',
    categoryId: '',
  });

  const [editFAQ, setEditFAQ] = React.useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSubCategoryOpen,
    onOpen: onSubCategoryOpen,
    onClose: onSubCategoryClose,
  } = useDisclosure();

  const {
    isOpen: isFAQOpen,
    onOpen: onFAQOpen,
    onClose: onFAQClose,
  } = useDisclosure();

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const fetchCategories = async () => {
    try {
      const response = await axios.post(`${baseURL}getHelpCategories`, {
        id: '67250ded0f689ab7f255f5f8',
      });
      setCategories(response.data.categories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  React.useEffect(() => {
    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubCategoryInputChange = (e) => {
    const { name, value } = e.target;
    setEditSubCategory((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveItem = async () => {
    try {
      let endpoint = '';
      const payload = {
        id: '67250ded0f689ab7f255f5f8',
        ...editItem,
      };
      if (editItem.categoryId) {
        endpoint = 'editHelpCategory';
      } else {
        endpoint = 'addHelpCategory';
      }
      await axios.post(`${baseURL}${endpoint}`, payload);
      fetchCategories();
      onClose();
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const handleSaveSubCategory = async () => {
    try {
      let endpoint = '';
      const payload = {
        id: '67250ded0f689ab7f255f5f8',
        ...editSubCategory,
      };
      if (editSubCategory.subcategoryId) {
        endpoint = 'editHelpSubcategory';
      } else {
        endpoint = 'addHelpSubcategory';
      }
      await axios.post(`${baseURL}${endpoint}`, payload);
      fetchCategories();
      onSubCategoryClose();
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const openEditModal = (item) => {
    setEditItem({
      en: item.name.en || '',
      es: item.name.es || '',
      fr: item.name.fr || '',
      it: item.name.it || '',
      pg: item.name.pg || '',
      ch: item.name.ch || '',
      ru: item.name.ru || '',
      categoryId: item._id,
    });
    onOpen();
  };

  const openEditSubCategoryModal = (item) => {
    setEditSubCategory({
      en: item.name.en || '',
      es: item.name.es || '',
      fr: item.name.fr || '',
      it: item.name.it || '',
      pg: item.name.pg || '',
      ch: item.name.ch || '',
      ru: item.name.ru || '',
      subcategoryId: item._id || '',
      categoryId: selectedCategory._id,
    });
    onSubCategoryOpen();
  };

  const handleAddItem = () => {
    setEditItem({
      en: '',
      es: '',
      fr: '',
      it: '',
      pg: '',
      ch: '',
      ru: '',
      categoryId: '',
    });
    onOpen();
  };

  const handleAddSubCategory = () => {
    setEditSubCategory({
      en: '',
      es: '',
      fr: '',
      it: '',
      pg: '',
      ch: '',
      ru: '',
      subcategoryId: '',
      categoryId: selectedCategory._id,
    });
    onSubCategoryOpen();
  };

  const handleFAQInputChange = (e) => {
    const { name, value } = e.target;
    setEditFAQ((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveFAQ = async (modalType) => {
    try {
      const endpoint = modalType === 'add' ? 'addArticle' : 'editArticle';
      const payload = {
        id: '67250ded0f689ab7f255f5f8',
        categoryId: selectedCategory._id,
        subcategoryId: selectedSubCategory._id,
        ...editFAQ,
      };

      await axios.post(`${baseURL}${endpoint}`, payload);
      fetchCategories();
      onFAQClose();
    } catch (error) {
      console.error('Error saving FAQ:', error);
    }
  };

  const openFAQModal = (faq = {}) => {
    setEditFAQ({
      headingEn: faq?.heading?.en || '',
      headingEs: faq?.heading?.es || '',
      headingFr: faq?.heading?.fr || '',
      headingIt: faq?.heading?.it || '',
      headingPg: faq?.heading?.pg || '',
      headingCh: faq?.heading?.ch || '',
      headingRu: faq?.heading?.ru || '',
      descriptionEn: faq?.description?.en || '',
      descriptionEs: faq?.description?.es || '',
      descriptionFr: faq?.description?.fr || '',
      descriptionIt: faq?.description?.it || '',
      descriptionPg: faq?.description?.pg || '',
      descriptionCh: faq?.description?.ch || '',
      descriptionRu: faq?.description?.ru || '',
      articleId: faq._id || null,
    });
    onFAQOpen();
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.post(`${baseURL}deleteHelpCategory`, {
        id: '67250ded0f689ab7f255f5f8',
        categoryId,
      });
      fetchCategories();
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleDeleteSubCategory = async (categoryId, subcategoryId) => {
    try {
      await axios.post(`${baseURL}deleteHelpSubcategory`, {
        id: '67250ded0f689ab7f255f5f8',
        categoryId,
        subcategoryId,
      });
      fetchCategories();
    } catch (error) {
      console.error('Error deleting subcategory:', error);
    }
  };

  const handleDeleteArticle = async (categoryId, subcategoryId, articleId) => {
    try {
      await axios.post(`${baseURL}deleteArticle`, {
        id: '67250ded0f689ab7f255f5f8',
        categoryId,
        subcategoryId,
        articleId,
      });
      fetchCategories();
    } catch (error) {
      console.error('Error deleting FAQ:', error);
    }
  };
  return (
    <Flex direction="column" w="100%" overflowX="auto">
      <Flex justify="space-between" w="100%" px="22px" pb="20px" mb="10px">
        {!showSubCategories && !showFAQs ? (
          <Text color={textColor} fontSize="xl" fontWeight="600">
            Help Categories
          </Text>
        ) : (
          <Button
            onClick={() => {
              if (showFAQs) {
                setShowFAQs(false);
                setShowSubCategories(true);
              } else {
                setShowSubCategories(false);
              }
            }}
          >
            Back
          </Button>
        )}
        <Button
          onClick={() => {
            if (showSubCategories) {
              handleAddSubCategory();
            } else if (showFAQs) {
              openFAQModal();
            } else {
              handleAddItem();
            }
          }}
        >
          Add{' '}
          {showSubCategories ? 'Sub Category' : showFAQs ? 'FAQ' : 'Category'}
        </Button>
      </Flex>

      {!showSubCategories && !showFAQs && (
        <Box>
          <Table variant="simple" color="gray.500" mt="12px">
            <Thead>
              <Tr>
                <Th>English</Th>
                <Th>Spanish</Th>
                <Th>French</Th>
                <Th>Italian</Th>
                <Th>Portuguese</Th>
                <Th>Chinese</Th>
                <Th>Russian</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {categories.map((item) => (
                <Tr
                  key={item._id}
                  onClick={() => {
                    setSelectedCategory(item);
                    setShowSubCategories(true);
                    setShowFAQs(false);
                  }}
                  cursor={'pointer'}
                >
                  <Td>{item.name.en}</Td>
                  <Td>{item.name.es}</Td>
                  <Td>{item.name.fr}</Td>
                  <Td>{item.name.it}</Td>
                  <Td>{item.name.pg}</Td>
                  <Td>{item.name.ch}</Td>
                  <Td>{item.name.ru}</Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => openEditModal(item)}
                    >
                      Edit
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {showSubCategories && !showFAQs && (
        <Box>
          <Table variant="simple" color="gray.500" mt="12px">
            <Thead>
              <Tr>
                <Th>English</Th>
                <Th>Spanish</Th>
                <Th>French</Th>
                <Th>Italian</Th>
                <Th>Portuguese</Th>
                <Th>Chinese</Th>
                <Th>Russian</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {selectedCategory &&
                selectedCategory.subcategories.map((item) => (
                  <Tr
                    key={item._id}
                    onClick={() => {
                      setSelectedSubCategory(item);
                      setShowSubCategories(false);
                      setShowFAQs(true);
                    }}
                    cursor={'pointer'}
                  >
                    <Td>{item.name.en}</Td>
                    <Td>{item.name.es}</Td>
                    <Td>{item.name.fr}</Td>
                    <Td>{item.name.it}</Td>
                    <Td>{item.name.pg}</Td>
                    <Td>{item.name.ch}</Td>
                    <Td>{item.name.ru}</Td>
                    <Td>
                      <Button
                        size="sm"
                        colorScheme="blue"
                        onClick={() => openEditSubCategoryModal(item)}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {showFAQs && !showSubCategories && (
        <Box>
          <Table variant="simple" color="gray.500" mt="12px">
            <Thead>
              <Tr>
                <Th>Article</Th>
                {selectedSubCategory &&
                  selectedSubCategory.articles.length > 0 &&
                  Object.keys(selectedSubCategory.articles[0].heading).map(
                    (lang) => (
                      <Th key={`lang-header-${lang}`}>{lang.toUpperCase()}</Th>
                    ),
                  )}
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {selectedSubCategory &&
                selectedSubCategory.articles.map((faq) => (
                  <>
                    <Tr key={`heading-${faq._id}`}>
                      <Td>Title</Td>
                      {Object.keys(faq.heading).map((lang) => (
                        <Td key={`heading-${faq._id}-${lang}`}>
                          {faq.heading[lang]}
                        </Td>
                      ))}
                      <Td rowSpan={2}>
                        <Button
                          size="sm"
                          colorScheme="blue"
                          onClick={() => openFAQModal(faq)}
                        >
                          Edit
                        </Button>
                      </Td>
                    </Tr>

                    <Tr key={`description-${faq._id}`}>
                      <Td>Description</Td>
                      {Object.keys(faq.description).map((lang) => (
                        <Td key={`description-${faq._id}-${lang}`}>
                          {faq.description[lang]}
                        </Td>
                      ))}
                    </Tr>
                  </>
                ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {/* FAQ Modal */}
      <Modal isOpen={isFAQOpen} onClose={onFAQClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editFAQ.articleId ? 'Edit Article' : 'Add Article'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Questions */}
            <FormControl mb="4">
              <FormLabel>Title (EN)</FormLabel>
              <Input
                type="text"
                name="headingEn"
                value={editFAQ.headingEn}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Title (ES)</FormLabel>
              <Input
                type="text"
                name="headingEs"
                value={editFAQ.headingEs}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Title (FR)</FormLabel>
              <Input
                type="text"
                name="headingFr"
                value={editFAQ.headingFr}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Title (IT)</FormLabel>
              <Input
                type="text"
                name="headingIt"
                value={editFAQ.headingIt}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Title (PG)</FormLabel>
              <Input
                type="text"
                name="headingPg"
                value={editFAQ.headingPg}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Title (CH)</FormLabel>
              <Input
                type="text"
                name="headingCh"
                value={editFAQ.headingCh}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Title (RU)</FormLabel>
              <Input
                type="text"
                name="headingRu"
                value={editFAQ.headingRu}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>

            <FormControl mb="4">
              <FormLabel>Description (EN)</FormLabel>
              <Input
                type="text"
                name="descriptionEn"
                value={editFAQ.descriptionEn}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Description (ES)</FormLabel>
              <Input
                type="text"
                name="descriptionEs"
                value={editFAQ.descriptionEs}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Description (FR)</FormLabel>
              <Input
                type="text"
                name="descriptionFr"
                value={editFAQ.descriptionFr}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Description (IT)</FormLabel>
              <Input
                type="text"
                name="descriptionIt"
                value={editFAQ.descriptionIt}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Description (PG)</FormLabel>
              <Input
                type="text"
                name="descriptionPg"
                value={editFAQ.descriptionPg}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Description (CH)</FormLabel>
              <Input
                type="text"
                name="descriptionCh"
                value={editFAQ.descriptionCh}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Description (RU)</FormLabel>
              <Input
                type="text"
                name="descriptionRu"
                value={editFAQ.descriptionRu}
                onChange={handleFAQInputChange}
                color={textColor}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={() => {
                handleSaveFAQ(editFAQ.articleId ? 'edit' : 'add');
              }}
            >
              Save
            </Button>
            <Button variant="ghost" onClick={onFAQClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Adding/Editing Category */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editItem.categoryId ? 'Edit Category' : 'Add Category'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.keys(editItem).map(
              (key) =>
                !['categoryId'].includes(key) && (
                  <FormControl key={key} mb="4">
                    <FormLabel textTransform="capitalize">{key}</FormLabel>
                    <Input
                      type="text"
                      name={key}
                      value={editItem[key]}
                      onChange={handleInputChange}
                      color={textColor}
                    />
                  </FormControl>
                ),
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSaveItem}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isSubCategoryOpen} onClose={onSubCategoryClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editSubCategory.subcategoryId
              ? 'Edit SubCategory'
              : 'Add SubCategory'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.keys(editSubCategory).map(
              (key) =>
                !['subcategoryId'].includes(key) && (
                  <FormControl key={key} mb="4">
                    <FormLabel textTransform="capitalize">{key}</FormLabel>
                    <Input
                      type="text"
                      name={key}
                      value={editSubCategory[key]}
                      onChange={handleSubCategoryInputChange}
                      color={textColor}
                    />
                  </FormControl>
                ),
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSaveSubCategory}>
              Save
            </Button>
            <Button variant="ghost" onClick={onSubCategoryClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
