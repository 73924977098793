/* eslint-disable */
import {
  Box,
  Button,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as React from 'react';
import axios from 'axios';
import { baseURL } from 'constants/constant';
import { useData } from 'contexts/dataContext';

const columnHelper = createColumnHelper();

export default function TopUserTable() {
  const [tableData, setTableData] = React.useState([]);
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [allTickets, setAllTickets] = React.useState([]);
  const [selectedSubadmin, setSelectedSubadmin] = React.useState(null);
  const [selectedTicket, setSelectedTicket] = React.useState(null);
  const [userStatus, setUserStatus] = React.useState(null);
  const [userTickets, setUserTickets] = React.useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isActionLog,
    onOpen: onActionLogOpen,
    onClose: onActionLogClose,
  } = useDisclosure();

  const {
    isOpen: isError,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();

  const {
    isOpen: isTicket,
    onOpen: onTicketOpen,
    onClose: onTicketClose,
  } = useDisclosure();

  const {
    isOpen: isActivate,
    onOpen: onActivateOpen,
    onClose: onActivateClose,
  } = useDisclosure();

  const [userIdToDelete, setUserIdToDelete] = React.useState(null);
  const [userIdToActivate, setUserIdToActivate] = React.useState(null);
  const [newAdmin, setNewAdmin] = React.useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    phoneNumber: {
      number: '',
    },
  });
  const { admin } = useData();

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
    onTicketOpen();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin({ ...newAdmin, [name]: value });
  };

  const handleAddAdmin = async () => {
    try {
      await axios.post(`${baseURL}createSupportAdmin`, newAdmin);
      const response = await axios.post(`${baseURL}getSupportAdmins`);
      setTableData(response.data.supportAdmins);
      onAddClose(); // Close the add admin modal
    } catch (error) {
      console.error('Error adding support admin:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.post(`${baseURL}deleteSupportAdmin`, {
        id,
      });
      const response = await axios.post(`${baseURL}getSupportAdmins`);
      setTableData(response.data.supportAdmins);
      onClose();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleActivation = async (status) => {
    try {
      await axios.post(`${baseURL}updateSubadminStatus`, {
        subAdminId: userIdToActivate,
        id: '67250ded0f689ab7f255f5f8',
        status,
      });
      const response = await axios.post(`${baseURL}getSupportAdmins`);
      setTableData(response.data.supportAdmins);
      onActivateClose();
    } catch (error) {
      console.error('Error adding support admin:', error);
    }
  };
  const columns = [
    columnHelper.accessor('firstName', {
      id: 'firstName',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          FIRST NAME
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('lastName', {
      id: 'lastName',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          LAST NAME
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="600">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('emailAddress', {
      id: 'email',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          EMAIL
        </Text>
      ),
      cell: (info) => (
        <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('active', {
      id: 'active',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          ACTIVE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColorSecondary} fontSize="sm" fontWeight="500">
          {info.getValue() === true ? 'Yes' : 'No'}
        </Text>
      ),
    }),
    columnHelper.accessor('actionLog', {
      id: 'actionLog',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          ACTION LOG
        </Text>
      ),
      cell: (info) => {
        const subAdmin = info.row.original; // Get the sub-admin details
        const email = subAdmin.emailAddress; // Admin's email
        const userTickets =
          allTickets &&
          allTickets.filter((ticket) => ticket.assignedTo === email); // Filter tickets assigned to the admin

        return (
          <Text
            color={textColorSecondary}
            fontSize="sm"
            fontWeight="500"
            cursor="pointer" // Make it clear the text is clickable
            onClick={() => {
              setSelectedSubadmin(subAdmin);

              onActionLogOpen(); // Open the modal
            }}
          >
            {userTickets?.length || 0} {/* Display the number of tickets */}
          </Text>
        );
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
          ACTIONS
        </Text>
      ),
      cell: (info) => {
        const userId = info.row.original._id;
        const active = info.row.original.active;
        const subAdmin = info.row.original;
        const email = subAdmin.emailAddress;
        const userTickets =
          allTickets &&
          allTickets.filter((ticket) => ticket.assignedTo === email);
        return (
          <Flex>
            <Button
              size="sm"
              colorScheme={active ? 'red' : 'green'}
              onClick={() => {
                if (active && userTickets.length > 0) {
                  alert('This SubAdmin has tickets assigned to them');
                  return;
                }
                setUserIdToActivate(userId);
                setUserTickets(userTickets);
                setUserStatus(active);
                onActivateOpen();
              }}
            >
              {active ? 'Deactivate' : 'Activate'}
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              ml="10px"
              onClick={() => {
                if (active && userTickets.length > 0) {
                  alert('This SubAdmin has tickets assigned to them');
                  return;
                }
                setUserIdToDelete(userId);
                onOpen();
              }}
            >
              Delete
            </Button>
          </Flex>
        );
      },
    }),
  ];

  React.useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.post(`${baseURL}getSupportAdmins`);
        setTableData(response.data.supportAdmins);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    const fetchAllTickets = async () => {
      try {
        const response = await axios.post(`${baseURL}getAllTickets`);
        setAllTickets(response.data.tickets);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };
    fetchUsers();
    fetchAllTickets();
  }, []);

  const table = useReactTable({
    data: tableData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        justify="space-between"
        w="100%"
        px="22px"
        pb="20px"
        mb="10px"
        boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
      >
        <Text color={textColor} fontSize="xl" fontWeight="600">
          All Sub Admins
        </Text>
        <Button colorScheme="blue" onClick={onAddOpen}>
          Add Support Admin
        </Button>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    borderColor={borderColor}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id} borderColor={borderColor}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} borderColor={borderColor}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* STATAUS Confirmation Modal */}
      <Modal isOpen={isActivate} onClose={onActivateOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{userStatus ? 'Deactivate' : 'Activate'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to {userStatus ? 'deactivate' : 'activate'}{' '}
              this SubAdmin?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              onClick={() => {
                handleActivation(userStatus ? 'false' : 'true');
              }}
            >
              {userStatus ? 'Deactivate' : 'Activate'}
            </Button>
            <Button variant="ghost" onClick={onActivateClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this user?</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              onClick={() => handleDelete(userIdToDelete)}
            >
              Delete
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Add Support Admin Modal */}
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Support Admin</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel>First Name</FormLabel>
              <Input
                name="firstName"
                value={newAdmin.firstName}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Last Name</FormLabel>
              <Input
                name="lastName"
                value={newAdmin.lastName}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Email</FormLabel>
              <Input
                name="emailAddress"
                value={newAdmin.email}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                name="password"
                value={newAdmin.password}
                onChange={handleInputChange}
                color={textColor}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddAdmin}>
              Add Admin
            </Button>
            <Button variant="ghost" onClick={onAddClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isActionLog} onClose={onActionLogClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Support Ticket Action Log</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedSubadmin && (
              <Box>
                <Text fontWeight="bold" mb={4}>
                  Tickets assigned to {selectedSubadmin.firstName}{' '}
                  {selectedSubadmin.lastName}:
                </Text>
                {allTickets
                  .filter(
                    (ticket) =>
                      ticket.assignedTo === selectedSubadmin.emailAddress,
                  )
                  .map((ticket) => (
                    <Box
                      key={ticket._id}
                      p={4}
                      mb={3}
                      borderWidth="1px"
                      borderRadius="md"
                      bg="gray.100"
                      _dark={{ bg: 'gray.700' }}
                      cursor="pointer" // Make the box clickable
                      onClick={() => handleTicketClick(ticket)}
                    >
                      <Text>
                        <strong>ID:</strong> {ticket.ticketID}
                      </Text>
                      <Text>
                        <strong>Status:</strong> {ticket.assigned}
                      </Text>
                      <Text>
                        <strong>Description:</strong>{' '}
                        {ticket.subject || 'No description'}
                      </Text>
                      <Text>
                        <strong>User:</strong> {ticket.email || 'No User'}
                      </Text>
                      <Text>
                        <strong>Support Admin:</strong>{' '}
                        {ticket.assignedTo || 'No Support'}
                      </Text>
                    </Box>
                  ))}
                {allTickets.filter(
                  (ticket) =>
                    ticket.assignedTo === selectedSubadmin.emailAddress,
                ).length === 0 && (
                  <Text>No tickets assigned to this sub-admin.</Text>
                )}
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onActionLogClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isTicket} onClose={onTicketClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ticket Messages</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedTicket && selectedTicket?.messages?.length > 0 ? (
              selectedTicket?.messages.map((message, index) => (
                <Box
                  key={index}
                  p={3}
                  mb={2}
                  borderWidth="1px"
                  borderRadius="md"
                  bg="gray.50"
                  _dark={{ bg: 'gray.800' }}
                >
                  <Text fontWeight="bold">
                    From:{' '}
                    {message.sender !== selectedSubadmin._id
                      ? 'User'
                      : 'SubAdmin'}
                  </Text>
                  <Text>{message.content}</Text>
                </Box>
              ))
            ) : (
              <Text>No messages found for this ticket.</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onTicketClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
